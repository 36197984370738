import React, { useState, useEffect, useRef } from 'react';
//@ts-ignore
import { Helmet } from 'react-helmet';
import useWindowDimensions from '../hooks/useWindowDimensions';
import Layout from '../layouts';
import Nav from '@components/common/Nav';
import Header from '@components/sections/Header';
import HowToGrow from '@components/sections/HowToGrow';
import JoinBuba from '@components/sections/JoinBuba';
import Roles from '@components/sections/Roles';
import Footer from '@components/sections/Footer';
import Product from '@components/sections/Product';
import Clients from '@components/sections/Clients';
// import Testimonials from '@components/sections/Testimonials';

const IndexPage: React.FC = () => {
  // @ts-ignore
  const { height, width } = useWindowDimensions();
  const [visibleSection, setVisibleSection] = useState<any>();

  const comerciosRef = useRef(null);
  const perfilesRef = useRef(null);
  const downloadRef = useRef(null);
  // const testimoniosRef = useRef(null);

  const sectionRefs = [
    { section: 'comercios', ref: comerciosRef },
    { section: 'perfiles', ref: perfilesRef },
    { section: 'descargar', ref: downloadRef },
    // { section: "testimonios", ref: testimoniosRef}
  ];

  const getDimension = (ele: any) => {
    const { height } = ele.getBoundingClientRect();
    const offsetTop = ele.offsetTop;
    const offsetBottom = offsetTop + height;

    return { height, offsetTop, offsetBottom };
  };

  const handleScroll = () => {
    const heightNav = 50;
    const scrollPosition = window.scrollY + heightNav;

    // @ts-ignore
    const selected = sectionRefs.find(({ section, ref }) => {
      const ele = ref.current;

      if (ele) {
        const { offsetBottom, offsetTop } = getDimension(ele);
        return (
          scrollPosition > offsetTop && scrollPosition < offsetBottom - 100
        );
      }
    });

    if (selected && selected.section !== visibleSection) {
      setVisibleSection(selected.section);
    } else if (!selected && visibleSection) {
      setVisibleSection(undefined);
    }
  };

  useEffect(() => {
    handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [visibleSection]);

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="BubaJobs | Portal de empleo en Argentina"
        />
        <title>BubaJobs | Portal de empleo en Argentina</title>
        <link rel="canonical" href="http://bubajobs.com.ar/" />
      </Helmet>
      <Nav activeSection={visibleSection} />
      <Header />
      <div id="comercios" ref={comerciosRef}>
        <HowToGrow />
      </div>
      <JoinBuba />
      <div id="perfiles" ref={perfilesRef}>
        <Roles />
      </div>
      <Product />
      <Clients />
      {/* <div id="testimonios" ref={testimoniosRef}>
        <Testimonials />
      </div> */}
      <div id="descargar" ref={downloadRef}>
        <Footer />
      </div>
    </Layout>
  );
};

export default IndexPage;
