import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// @ts-ignore
import LaPaperia from '@assets/images/clients/1LaPaperia.png';
// @ts-ignore
import NewParking from '@assets/images/clients/2NEWPARKING.png';
// @ts-ignore
import ParkingCar from '@assets/images/clients/3PARKINGCAR.png';
// @ts-ignore
import OriginalParking from '@assets/images/clients/4original-logo-parking.png'
// @ts-ignore
import Paz from '@assets/images/clients/5Paz.png';
// @ts-ignore
import LaNuna from '@assets/images/clients/6lanuna.png';
// @ts-ignore
import Sarkany from '@assets/images/clients/7sarkany.png';
// @ts-ignore
import Grafpro from '@assets/images/clients/8grafpro.png';
import { ClientsWrapper, ClientsContainer } from './index.styled';

const Clients: React.FC = () => {
  return (
    <ClientsContainer>
      <h2 className='title-join'>
        Clientes que confian en nosotros
      </h2>
      <ClientsWrapper>
      <Swiper
        slidesPerView={1}
        slidesPerGroup={1}
        spaceBetween={10}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false
        }}
        loop={true}
        loopFillGroupWithBlank={true}
        navigation={true}
        modules={[Autoplay, Navigation]}
        breakpoints={{
          768: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            spaceBetween: 20
          },
          1024: {
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: 30
          }
        }}
      >
        <SwiperSlide>
          <img src={LaPaperia} alt="La Paperia" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={NewParking} alt="New Parking" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={ParkingCar} alt="Parking Car" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={OriginalParking} alt="ParkingPaz" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Paz} alt="Paz" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={LaNuna} alt="La Nuna" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Sarkany} alt="Sarkany" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Grafpro} alt="GrafPro" />
        </SwiperSlide>
      </Swiper>
    </ClientsWrapper>
    </ClientsContainer>
  )
}

export default Clients;