import React from 'react';
import Button from '@components/common/Button';
// @ts-ignore
import { RolProps } from '@lib/types';
import { StyledRol } from './index.styled';
import { color } from '@theme/color';
//@ts-ignore
import arrow from '@assets/icons/item.svg';
//@ts-ignore
import { ReactComponent as ArrowDown } from '@assets/icons/arrow-circle-down.svg';
//@ts-ignore
import { ReactComponent as CommerceIcon } from '@assets/icons/comercio.svg';
import { isAndroid, isIOS } from 'react-device-detect';

const Rol: React.FC<RolProps> = ({ state, title, items, imgs }) => {
  const handleDownload = () => {
    if (isAndroid) {
      window.location.href =
        'https://play.google.com/store/apps/details?id=com.bubajobs.app';
    } else if (isIOS) {
      window.location.href =
        'https://apps.apple.com/us/app/bubajobs/id1616932294';
    } else {
      window.location.href =
        'https://play.google.com/store/apps/details?id=com.bubajobs.app';
    }
  };
  return (
    <StyledRol>
      <h2 className="title-rol">{title}</h2>
      <picture className={`img-rol img-${state}`}>
        <source media="(min-width: 1024px)" srcSet={imgs[1]} />
        <img src={imgs[0]} alt="Perfil en Buba" />
      </picture>
      <div>
        <h2 className="title-rol-2">{title}</h2>
        <ul className="list-rol">
          {items.map((item, key) => (
            <li key={key + state}>
              <img src={arrow} alt="item" />
              <span>{item}</span>
            </li>
          ))}
        </ul>
        <div className="btns-wrapper">
          {state === 'commerce' ? (
            <Button
              isIntern={true}
              link="https://comercios.bubajobs.com.ar"
              icon={<CommerceIcon fill="white" />}
              text="Registrar mi comercio"
              bgColor={color.black}
              wBtn={260}
            />
          ) : (
            ''
          )}
          <Button
            isIntern={true}
            link="#"
            onClick={handleDownload}
            icon={<ArrowDown fill="white" />}
            text="Descargar Buba"
            bgColor={color.red}
            wBtn={200}
          />
        </div>
      </div>
    </StyledRol>
  );
};

export default Rol;
