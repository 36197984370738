import styled from 'styled-components';
import { color } from '@theme/color';
import { px2rem } from '@styles/styleUtils';
import { device } from '@theme/size';

export const StyledJoin = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${color.darkGrey};
  padding: ${px2rem(56)} 0;
  overflow: hidden;

  .title-join {
    width: 90%;
    font-family: "Messapia", sans-serif;
    font-size: ${px2rem(24)};
    color: ${color.white};
    text-align: center;
    margin-bottom: ${px2rem(50)};
  }

  .join-banner {
    width: 270%;
    overflow: hidden;
  }

  @media ${device.tablet} {
    .title-join {
      max-width: ${px2rem(600)};
    }

    .join-banner {
      width: 150%;
    }
  }

  @media ${device.laptop} {
    .title-join {
      font-size: ${px2rem(32)};
      max-width: ${px2rem(706)};
    }

    .join-banner {
      width: 125%;
    }
  }

  @media ${device.laptopL} {
    .join-banner {
      width: 100%;
    }
  }
`