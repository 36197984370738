import React, { useState } from 'react';
import Rol from '@components/common/Rol';
import { StyledRoles } from './index.styled';
//@ts-ignore
import commerceM from '@assets/images/commerce-1.png';
//@ts-ignore
import peopleM from '@assets/images/people-1.png';
//@ts-ignore
import communityM from '@assets/images/community-1.png';
//@ts-ignore
import commerceD from '@assets/images/commerce-2.png';
//@ts-ignore
import peopleD from '@assets/images/people-2.png';
//@ts-ignore
import communityD from '@assets/images/community-2.png';


const Roles: React.FC = () => {
  const roles = {
    empleadores: {
      state: 'commerce',
      title: 'Empleados a la altura de tu comercio.',
      items: [
        'Subí varias búsquedas al mismo tiempo',
        'Calificá a tus empleados',
        'Buscá personal para trabajos temporarios',
        'Contratá empleados de confianza más rápido'
      ],
      imgs: [commerceM, commerceD]
    },
    empleados: {
      state: 'people',
      title: 'El puesto que queres, como lo querés.',
      items: [
        'Ofertas de trabajo por zona',
        'Trabajos profesionales, de oficio o temporales',
        'Recibí valoraciones y potenciá tu cv',
        'Deja tus devoluciones y califica el lugar de trabajo',
        'Conseguí empleo rápido y fácil'
      ],
      imgs: [peopleM, peopleD]
    },
    comunidad: {
      state: 'community',
      title: 'Una comunidad dispuesta a compartir.',
      items: [
        'Subí anuncios de trabajo automáticamente',
        'Conectá las oportunidades con los postulantes',
        'Ayudá a las otras personas',
      ],
      imgs: [communityM, communityD]
    },
  };

  const [rol, setRol] = useState(roles.empleados.state);

  return (
    <StyledRoles rol={rol}>
      <div className="rol-wrapper">
        <div className="btn-group">
          <button
            className="btn-rol"
            id="btn-commerce"
            onClick={() => setRol(roles.empleadores.state)}
          >
            Comercios
          </button>
          <button
            className="btn-rol"
            id="btn-people"
            onClick={() => setRol(roles.empleados.state)}
          >
            Empleados
          </button>
          <button
            className="btn-rol"
            id="btn-community"
            onClick={() => setRol(roles.comunidad.state)}
          >
            Comunidad
          </button>
        </div>
        <div id="commerce">
          <Rol
            state={roles.empleadores.state}
            title={roles.empleadores.title}
            items={roles.empleadores.items}
            imgs={roles.empleadores.imgs}
          />
        </div>
        <div id="people">
          <Rol
            state={roles.empleados.state}
            title={roles.empleados.title}
            items={roles.empleados.items}
            imgs={roles.empleados.imgs}
          />
        </div>
        <div id="community">
          <Rol
            state={roles.comunidad.state}
            title={roles.comunidad.title}
            items={roles.comunidad.items}
            imgs={roles.comunidad.imgs}
          />
        </div>
      </div>
    </StyledRoles>
  );
};

export default Roles;
