import styled from 'styled-components';
import { px2rem } from '@styles/styleUtils';
import { device } from '@theme/size';

export const StyledGrow = styled.section`
  display: flex;
  justify-content: center;

  .howtogrow-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 ${px2rem(10)};

    .title-grow {
      font-family: 'messapia', sans-serif;
      line-height: ${px2rem(40)};
      margin: ${px2rem(32)} 0 ${px2rem(24)} 0;
      width: ${px2rem(300)};
      align-self: flex-start;
      line-height: ${px2rem(24)};
    }

    .steps {
      max-width: ${px2rem(328)};
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .btn-wrapper > * {
      margin-bottom: ${px2rem(74)};
    }
  }

  @media ${device.mobileM} {
    .howtogrow-wrapper {
      padding: 0 ${px2rem(16)};
    }
  }

  @media ${device.tablet} {
    .howtogrow-wrapper {
      align-items: center;
      margin-top: ${px2rem(80)};

      .steps {
        max-width: none;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
      }

      .title-grow {
        width: ${px2rem(720)};
        font-size: ${px2rem(32)};
        line-height: ${px2rem(40)};
        margin-top: 0;
      }

      .btn-wrapper > * {
        margin-bottom: ${px2rem(80)};
      }
    }
  }

  @media ${device.laptop} {
    .howtogrow-wrapper {
      justify-content: center;
      max-width: ${px2rem(1000)};

      .title-grow {
        width: ${px2rem(720)};
      }
    }
  }

  @media ${device.laptopL} {
    .howtogrow-wrapper {
      width: 100%;
      max-width: ${px2rem(1032)};
    }
  }
`;
