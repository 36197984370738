import styled from 'styled-components';
import { device } from '@theme/size';
import { px2rem } from '@styles/styleUtils';

export const StyledHeader = styled.header`
  display: flex;
  justify-content: center;
  
  .header-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    
    .text-header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 2rem ${px2rem(6)} 2rem ${px2rem(10)};
      
      h1 {
        font-family: 'messapia', sans-serif;
        font-size: ${px2rem(32)};
        letter-spacing: ${px2rem(0)};
        max-width: ${px2rem(327)};
        margin-bottom: ${px2rem(15)};
      }
    }
  
    picture {
      width: 100%;

      img, source {
        width: 100%;
      }
    }
  }

  @media ${device.mobileM} {
    .header-container > .text-header {
      margin-left: ${px2rem(16)};
    }
  }

  @media ${device.tablet} {
    max-height: calc(100vh - 105px);

    .header-container{
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
  
      .text-header {
        width: ${100 - 45}%;
        margin-left: ${px2rem(20)};
  
        & > * {
          margin-left: 0;
        }
  
        h1 {
          max-width: ${px2rem(560)};
          margin-bottom: ${px2rem(27)};
        }
      }
      picture {
        max-width: 56%;
        max-height: calc(100vh - 102px);
        
        img, source {
          width: 100%;
          object-fit: scale-down;
        }
      }
    }
  }

  @media ${device.laptop} {
    .header-container{
      .text-header {
        margin-left: ${px2rem(55)};
  
        & > * {
          margin-left: 0;
        }
  
        h1 {
          font-size: ${px2rem(40)};
          max-width: ${px2rem(500)};
        }
      }
    }
  }

  @media ${device.laptopL} {
    .header-container{
      width: 100%;
      max-width: ${px2rem(1440)};

      .text-header {
        margin-left: ${px2rem(65)};
  
        & > * {
          margin-left: 0;
        }
  
        h1 {
          width: 100%;
          font-size: ${px2rem(56)};
          max-width: ${px2rem(600)};
        }
      }
    }
  }
`;
