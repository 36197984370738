import React from 'react';
import { StyledJoin } from './index.styled'
//@ts-ignore
import joinUs from '@assets/images/banner.png'

const JoinBuba: React.FC = () => {
  return (
    <StyledJoin>
      <h2 className="title-join">
        Seamos parte de la comunidad más grande de trabajadores
      </h2>
      <img className="join-banner" src={joinUs} alt="Buba" />
    </StyledJoin>
  )
}

export default JoinBuba;