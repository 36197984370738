import { px2rem } from '@styles/styleUtils';
import { color } from '@theme/color';
import { device } from '@theme/size';
import styled from 'styled-components';

export const StyledProduct = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${px2rem(24)} 0 ${px2rem(40)} 0;

  .searchs-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    img { width: 100%; }

    .effective-search {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: ${px2rem(50)} 0;

      h2 {
        font-family: 'messapia', sans-serif;
        font-size: ${px2rem(24)};
        color: ${color.black};
        margin-bottom: ${px2rem(16)};
        width: ${px2rem(220)};
      }

      p {
        font-family: 'lexend Mega', sans-serif;
        font-size: ${px2rem(18)};
        color: ${color.palette.shades.mediumGray};
        letter-spacing: ${px2rem(-2)};
        width: ${px2rem(310)};
      }
    }
  }

  .road-job {
    width: 100%;
    max-width: ${px2rem(310)}
  }

  .profiles-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .simple-profiles {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: ${px2rem(40)} 0;

      h2 {
        font-family: 'messapia', sans-serif;
        font-size: ${px2rem(24)};
        color: ${color.black};
        margin-bottom: ${px2rem(16)};
        width: ${px2rem(220)};
      }

      p {
        font-family: 'lexend Mega', sans-serif;
        font-size: ${px2rem(18)};
        color: ${color.palette.shades.mediumGray};
        letter-spacing: ${px2rem(-2)};
        width: ${px2rem(310)};
      }
    }

    img { width: 100%; }
  }

  @media ${device.mobileM} {
    padding: ${px2rem(24)} ${px2rem(10)} ${px2rem(40)} ${px2rem(10)};

    .road-job {
      max-width: none;
    }
  }

  @media ${device.tablet} {
    padding: ${px2rem(24)} ${px2rem(20)} ${px2rem(40)} ${px2rem(20)};

    .searchs-wrapper {
      flex-direction: row-reverse;
      justify-content: space-evenly;

      img { max-width: ${px2rem(350)}; }

      .effective-search { max-width: ${px2rem(350)}; }
    }

    .road-job { width: 80%; }

    .profiles-wrapper {
      flex-direction: row-reverse;
      justify-content: space-evenly;

      .simple-profiles { max-width: ${px2rem(350)}; }

      img { max-width: ${px2rem(350)}; }
    }
  }

  @media ${device.laptop} {
    .searchs-wrapper, .profiles-wrapper {
      justify-content: space-between;
      width: 100%;
      max-width: ${px2rem(850)};
    }

    .profiles-wrapper {
      max-width: ${px2rem(850)};
    }

    .searchs-wrapper { .effective-search { h2, p {
      width: ${px2rem(400)};
    } } }

    .road-job {
      margin: ${px2rem(32)} 0;
      max-width: ${px2rem(850)};
    }

    .profiles-wrapper { .simple-profiles { h2, p {
      width: ${px2rem(370)};
    } } }
  }

  @media ${device.laptopL} {
    .searchs-wrapper {
      max-width: ${px2rem(1032)};

      img { max-width: ${px2rem(420)}; }

      .effective-search {
        max-width: ${px2rem(530)};

        h2, p {
          width: 100%;
        }

        h2 { font-size: ${px2rem(32)}; }

        p { font-size: ${px2rem(24)}; }
      }
    }

    .road-job { max-width: ${px2rem(1032)}; }

    .profiles-wrapper {
      max-width: ${px2rem(1032)};

      .simple-profiles {
        max-width: ${px2rem(510)};

        h2, p {
          width: 100%;
        }

        h2 { font-size: ${px2rem(32)}; }

        p { font-size: ${px2rem(24)}; }
      }

      img { max-width: ${px2rem(420)}; }
    }
  }
`;
