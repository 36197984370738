import React from 'react';
// @ts-ignore
import { StepStyle } from '@lib/types';
import { StyledStep } from './index.styled';


const GrowStep: React.FC<StepStyle> = ({ image, title, subtitle }) => {
  return (
    <StyledStep>
      <img src={image} alt={title} />
      <h3 className="title-step">{title}</h3>
      <p>{subtitle}</p>
    </StyledStep>
  );
};

export default GrowStep;
