import styled from 'styled-components';
import { px2rem } from '@styles/styleUtils';
import { device } from '@theme/size';

export const StyledStep = styled.div`
  display: flex;
  flex-direction: column;
  width: ${px2rem(300)};
  max-width: ${px2rem(328)};
  margin-bottom: ${px2rem(24)};

  img {
    width: ${px2rem(300)};
    margin-bottom: ${px2rem(24)};
  }

  .title-step {
    width: 100%;
    font-family: 'lexend Mega', sans-serif;
    font-size: ${px2rem(20)};
    letter-spacing: ${px2rem(-3)};
    text-align: left;
  }

  p {
    font-family: 'Lexend', sans-serif;
    font-size: ${px2rem(16)};
    max-width: ${px2rem(240)};
  }

  @media ${device.mobileM} {
    width: ${px2rem(330)};

    img {
      width: ${px2rem(328)};
    }
  }

  @media ${device.tablet} {
    width: ${px2rem(220)};

    img {
      width: 100%;
    }

    p {
      font-size: ${px2rem(14)}
    }
  }

  @media ${device.laptop} {
    width: ${px2rem(320)};
    margin-bottom: ${px2rem(40)};

    p {
      font-size: ${px2rem(16)}
    }
  }
`;
