import styled from 'styled-components';
import { px2rem } from '@styles/styleUtils';
import { device } from '@theme/size';

export const StyledRol = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .title-rol,
  .title-rol-2 {
    font-family: 'messapia', sans-serif;
    font-size: ${px2rem(24)};
    margin: 0 0 ${px2rem(12)} ${px2rem(16)};
  }

  .title-rol-2 {
    margin-top: ${px2rem(35)};
    margin-left: ${px2rem(10)};
    width: ${px2rem(380)};
    display: none;
  }

  .img-rol {
    max-width: ${px2rem(425)};

    img, source {
      width: 100%;
    }
  }

  .img-commerce {
    margin-top: ${px2rem(30)};
  }

  .list-rol {
    font-family: 'lexend Mega', sans-serif;
    font-size: ${px2rem(16)};
    letter-spacing: ${px2rem(-2)};
    margin-bottom: ${px2rem(24)};

    li {
      display: flex;
      margin: ${px2rem(8)} 0;

      img {
        width: ${px2rem(14)};
        height: ${px2rem(14)};
        margin: ${px2rem(3)} ${px2rem(10)};
      }
    }
  }

  .btns-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: ${px2rem(24)} ${px2rem(20)} ${px2rem(40)} ${px2rem(20)};

    & > * { margin: 0 ${px2rem(4)} ${px2rem(12)} ${px2rem(4)}; }
  }

  @media ${device.mobileL} {
    .title-rol {    
      margin: 0 ${px2rem(10)} ${px2rem(12)} ${px2rem(10)};
    }
  }

  @media ${device.tablet} {
    flex-direction: row;

    .title-rol { display: none; }

    .title-rol-2 {
      display: flex;
      font-size: ${px2rem(32)};
      min-width: ${px2rem(400)};
    }

    .img-rol {
      max-width: none;

      img, source {
        width: ${px2rem(350)};
      }
    }

    .list-rol {
      margin-right: ${px2rem(20)};
    }

    .btns-wrapper {
      justify-content: flex-start;
      padding: 0;

      & > * { margin: 0 ${px2rem(8)} ${px2rem(12)} ${px2rem(8)}; }
    }
  }

  @media ${device.laptop} {
    .title-rol-2 { width: ${px2rem(480)}; }

    .img-rol {
      margin-right: ${px2rem(20)};

      img, source {
        width: ${px2rem(500)};
      }
    }

    .img-commerce{
      margin-top: ${px2rem(0)};
    }
  }

  @media ${device.laptopL} {
    .title-rol-2 {
      font-size: ${px2rem(48)};
      width: ${px2rem(560)};
    }

    .img-rol {
      margin-right: ${px2rem(50)};

      img, source {
        width: ${px2rem(676)};
      }
    }
  }
`;
