import styled from 'styled-components';
// @ts-ignore
import { RolProp } from '@lib/types';
import { px2rem } from '@styles/styleUtils';
import { color } from '@theme/color';
import { device } from '@theme/size';


export const StyledRoles = styled.section<RolProp>`
  padding: ${px2rem(25)} 0;
  display: flex;
  justify-content: center;
  
  .rol-wrapper {
    width: 100%;
    
    .btn-group {
      margin-bottom: ${px2rem(39)};
      display: flex;
      justify-content: center;
      
      .btn-rol {
        background-color: #f6f6f6;
        border: none;
        padding: ${px2rem(7)} ${px2rem(10)};
        font-family: "Lexend", sans-serif;
        font-size: ${px2rem(14)};
        cursor: pointer;
        
        @media ${device.mobileM}{
          padding: ${px2rem(7)} ${px2rem(22.17)};
        }

        @media ${device.tablet}{
          padding: ${px2rem(12)} ${px2rem(49)};
          font-size: ${px2rem(16)};
        }
      }

      #btn-commerce {
        border-radius: ${px2rem(12)} 0 0 ${px2rem(12)};
        color: ${props => props.rol === "commerce" ? color.white : color.black};
        background-color: ${props => props.rol === "commerce" ? color.lightPurple : "#F6F6F6"};
      }

      #btn-people {
        color: ${props => props.rol === "people" ? color.white : color.black};
        background-color: ${props => props.rol === "people" ? color.passionPink : "#F6F6F6"};
      }

      #btn-community {
        border-radius: 0 ${px2rem(12)} ${px2rem(12)} 0;
        color: ${props => props.rol === "community" ? color.white : color.black};
        background-color: ${props => props.rol === "community" ? color.leafGreen : "#F6F6F6"};
      }
    }

    #commerce {
      display: ${props => props.rol === "commerce" ? "flex" : "none"};
    }

    #people {
      display: ${props => props.rol === "people" ? "flex" : "none"};
    }

    #community {
      display: ${props => props.rol === "community" ? "flex" : "none"};
    }
  }

  
  @media ${device.tablet}{
    padding: ${px2rem(64)} 0;
  }

  @media ${device.laptopL} {
    .rol-wrapper {
      max-width: ${px2rem(1440)};
    }
  }
`