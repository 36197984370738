import React from 'react';
import { StyledProduct } from './index.styled';
//@ts-ignore
import jobs from '@assets/images/jobs.png';
//@ts-ignore
import road from '@assets/images/perfil-search.png';
//@ts-ignore
import perfil from '@assets/images/simple-perfil.png';

const Product: React.FC = () => {
  return (
    <StyledProduct>
      <div className="searchs-wrapper">
        <img src={jobs} alt="jobs" />
        <div className="effective-search">
          <h2>Búsquedas efectivas</h2>
          <p>Una solución práctica y segura para no perder tiempo ni dinero.</p>
        </div>
      </div>

      <img className="road-job" src={road} alt="road" />

      <div className="profiles-wrapper">
        <div className="simple-profiles">
          <h2>Perfiles simples</h2>
          <p>
            Toda la información necesaria para conseguir tu próximo empleo de
            forma rápida y sencilla.
          </p>
        </div>
        <img src={perfil} alt="perfil" />
      </div>
    </StyledProduct>
  );
};

export default Product;
