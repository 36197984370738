import styled from 'styled-components';
import { color } from '@theme/color';
import { px2rem } from '@styles/styleUtils';
import { device } from '@theme/size';

export const ClientsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  .swiper {
    width: 1200px;
    min-height: 230px;
  }

  .swiper-slide {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 150px;
    height: auto;
    max-height: 130px;
    object-fit: cover;
  }

  .swiper-button-prev, .swiper-button-next {
    color: ${color.red};

    &::after {
      font-size: 25px;
      font-weight: bold;
    }
  }
`

export const ClientsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .title-join {
    width: 90%;
    font-family: "Messapia";
    font-size: ${px2rem(24)};
    color: ${color.black};
    text-align: center;
  }

  @media ${device.laptopL} {
    .title-join {
      font-size: ${px2rem(32)};
    }
  }
`