import GrowStep from '@components/common/GrowStep';
import React from 'react';
import Button from '@components/common/Button';
import { StyledGrow } from './index.styled';
import { color } from '@theme/color';
//@ts-ignore
import grow1 from '@assets/images/grow1.png';
//@ts-ignore
import grow2 from '@assets/images/grow2.png';
//@ts-ignore
import grow3 from '@assets/images/grow3.png';
//@ts-ignore
import { ReactComponent as CommerceIcon } from '@assets/icons/comercio.svg';


const HowToGrow: React.FC = () => {
  const steps = [
    {
      image: grow1,
      title: 'Generá empleo',
      subtitle: 'Publicá las búsquedas que necesites para tu negocio',
    },
    {
      image: grow2,
      title: 'Seleccioná al indicado',
      subtitle: 'Recibí solicitudes de cientos de postulantes interesados',
    },
    {
      image: grow3,
      title: 'Agrandá tu equipo',
      subtitle: 'Encontrá el empleado perfecto y contanos cómo te fue',
    },
  ];

  return (
    <StyledGrow>
      <div className="howtogrow-wrapper">
        <h2 className="title-grow">
          Hacé crecer tu negocio con <u>empleados de confianza</u>
        </h2>
        <div className="steps">
          {steps.map((step) => (
            <GrowStep
              key={step.title}
              image={step.image}
              title={step.title}
              subtitle={step.subtitle}
            />
          ))}
        </div>
        <div className="btn-wrapper">
          <Button
            isIntern={true}
            link="https://comercios.bubajobs.com.ar"
            icon={<CommerceIcon fill="white" />}
            text="Registrar mi comercio"
            bgColor={color.black}
            wBtn={260}
          />
        </div>
      </div>
    </StyledGrow>
  );
};

export default HowToGrow;
