import Button from '@components/common/Button';
import React from 'react';
import { StyledHeader } from './index.styled';
//@ts-ignore
import mapaDesktop from '@assets/images/phone-map-desktop.png';
//@ts-ignore
import mapaMobile from '@assets/images/phone-map-mobile.png';
//@ts-ignore
import { ReactComponent as ArrowDown } from '@assets/icons/arrow-circle-down.svg';
import { color } from '@theme/color';
import { isAndroid, isIOS } from 'react-device-detect';

const Header: React.FC = () => {
  const handleDownload = () => {
    if (isAndroid) {
      window.location.href =
        'https://play.google.com/store/apps/details?id=com.bubajobs.app';
    } else if (isIOS) {
      window.location.href =
        'https://apps.apple.com/us/app/bubajobs/id1616932294';
    } else {
      window.location.href =
        'https://apps.apple.com/us/app/bubajobs/id1616932294';
    }
  };
  return (
    <StyledHeader>
      <div className="header-container">
        <div className="text-header">
          <h1>Tu próximo trabajo, más cerca de lo que pensás</h1>
          <Button
            isIntern={true}
            onClick={handleDownload}
            link="#"
            icon={<ArrowDown fill="white" />}
            text="Descargar Buba"
            bgColor={color.red}
            wBtn={200}
          />
        </div>
        <picture>
          <source media="(min-width: 768px)" srcSet={mapaDesktop} />
          <img src={mapaMobile} alt="Mapa" />
        </picture>
      </div>
    </StyledHeader>
  );
};

export default Header;
